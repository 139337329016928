import { Box, Typography, Button, CircularProgress, Stack, Tooltip, Popover } from "@mui/material";
import { Form, Formik } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../context/snackBarContext";
import { getUrlS3, useCreateNewGroup, useUpdateGroup } from "../../../lib/usersBEClient";
import { groupSchema } from "../../../lib/Validations/inputSchemas";
import { LoadingButton } from "../../LoadingButton";
import { InputImage } from "../../Inputs/InputImage";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../lib/s3Client";
import { InputFile } from "../../Inputs/InputFile";
import { FileWithDateInterface, Group } from "../../../types/BaseTypes";
import { ClientCreationContext } from "../../../context/client/clientCreationContext";
import { ChromePicker } from "react-color";

export const GroupDetailsComponent = ({ onComplete }) => {
   const { selectedClient, setSelectedClient, refetch, isLoading } = useContext(ClientCreationContext);
   const group = selectedClient.data as Group;

   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate: createCompany } = useCreateNewGroup();
   const { mutate: updateMutate } = useUpdateGroup();
   const [companyLogo, setCompanyLogo] = useState<FileWithDateInterface>();
   const [isLoadingUpload, setIsLoadingUpload] = useState(false);
   const [contractFile, setContractFile] = useState<FileWithDateInterface>();
   const [isLoadingFiles, setIsLoadingFiles] = useState(!!group);
   const [selectedColorPicker, setSelectedColorPicker] = useState("");
   const [primaryColor, setPrimaryColor] = useState("#162c44");
   const [secondaryColor, setSecondaryColor] = useState("#162c44");
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const open = Boolean(anchorEl);
   const popoverId = open ? "simple-popover" : undefined;

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedColorPicker(event.currentTarget.id);
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleChangeComplete = (color) => {
      if (selectedColorPicker === "color-picker-1") setPrimaryColor(color.hex);
      else setSecondaryColor(color.hex);
   };

   const handleCancel = () => setSelectedClient(null);

   const fetchFile = async (bucket: string, folder: string, fileName: string, fileType: string) => {
      const url = await getUrlS3(bucket, { folder }, fileName);
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], fileName, { type: fileType });
   };

   const getLogo = async (groupId: string) => {
      const file = await fetchFile("images-lecosy", `group/${groupId}`, "logo.png", "image/png");
      setCompanyLogo({ file, lastDateModified: new Date() });
   };

   const getContract = async (groupId: string) => {
      const file = await fetchFile("files-lecosy", `contracts/group/${groupId}`, "contract.pdf", "application/pdf");
      setContractFile({ file, lastDateModified: new Date() });
      setIsLoadingFiles(false);
   };

   useEffect(() => {
      if (group) {
         if (!companyLogo) getLogo(group._id);
         if (!contractFile) getContract(group._id);
         setPrimaryColor(group.primaryColor || "#162c44");
         setSecondaryColor(group.secondaryColor || "#5d6d7c");
      }
   }, [group]);

   const handleSubmit = async (values) => {
      try {
         if (!companyLogo) {
            return showSnackBar("Favor de adjuntar archivos correspondientes", true);
         }
         if (group) {
            updateMutate(
               {
                  _id: group._id,
                  name: values.name,
                  phoneNumber: values.phoneNumber,
                  lastName: values.lastName,
                  activity: values.activity,
                  primaryColor: primaryColor,
                  secondaryColor: secondaryColor,
               },
               {
                  onSuccess: async (e) => {
                     await handleUploadFiles(group._id);
                     refetch();
                     onComplete();
                     showSnackBar("El cliente fue modificado", false);
                  },
                  onError: () => {
                     showSnackBar("Error al modificar el cliente", true);
                  },
               }
            );
         } else if (values.name && companyLogo) {
            createCompany(
               { name: values.name, phoneNumber: values.phoneNumber },
               {
                  onSuccess: async (e) => {
                     refetch();
                     onComplete();
                     showSnackBar("El grupo fue creado correctamente", false);
                  },
                  onError: () => {
                     showSnackBar("Error al crear grupo", true);
                  },
               }
            );
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   const handleUploadFiles = async (groupId: string) => {
      setIsLoadingUpload(true);
      const urlContract = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `contracts/group/${groupId}`,
         name: "contract.pdf",
      });
      const urlLogo = await UseGetPresignedURLUpload({
         bucket: "images-lecosy",
         folder: `group/${groupId}`,
         name: "logo.png",
      });
      await uploadFileToS3(urlLogo.data, companyLogo.file);
      if (contractFile) {
         await uploadFileToS3(urlContract.data, contractFile.file);
      }
      setIsLoadingUpload(false);
   };

   return (
      <Box sx={{ flex: 1 }}>
         {!isLoading ? (
            <Formik
               initialValues={{
                  name: group ? group.name : "",
                  phoneNumber: group?.phoneNumber ? group.phoneNumber : "",
               }}
               onSubmit={handleSubmit}
               validationSchema={groupSchema}
            >
               <Form>
                  <Box
                     sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 2,
                     }}
                  >
                     <Box sx={{ p: 2 }}>
                        <Typography fontWeight={600} variant="h6">
                           {`Datos del grupo empresarial`}
                        </Typography>
                     </Box>

                     <Box
                        sx={{
                           p: 4,
                           borderTop: 1,
                           borderColor: "#E0E0E0",
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                           maxHeight: "420px",
                           overflowY: "auto",
                        }}
                     >
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="name"
                           id="name"
                           type="text"
                           label="Nombre comercial"
                           fullWidth={true}
                        />
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="phoneNumber"
                           id="phoneNumber"
                           type="text"
                           label="Numero de contacto"
                           fullWidth={true}
                        />
                        <Box display={"flex"} alignItems={"center"} columnGap={2}>
                           <Typography>Contrato:</Typography>
                           <InputFile file={contractFile?.file} setFile={setContractFile} keyName="contract" />
                        </Box>
                        <Box display={"flex"} columnGap={5}>
                           <Box display={"flex"} columnGap={3}>
                              <Typography>Logo del grupo empresarial:</Typography>
                              <InputImage image={companyLogo?.file} setImage={setCompanyLogo} keyName="companyLogo" />
                           </Box>
                           <Box display={"flex"} flex={2} justifyContent={"center"}>
                              <Stack>
                                 <Typography>Color corporativo:</Typography>
                                 <Stack sx={{ rowGap: 1, mt: 0.5 }}>
                                    <Tooltip title={"Color primario"} placement="right-end">
                                       <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                          <Button
                                             id="color-picker-1"
                                             aria-describedby={popoverId}
                                             sx={{
                                                borderRadius: 1,
                                                width: 35,
                                                height: 20,
                                                bgcolor: primaryColor,
                                                ":hover": { bgcolor: primaryColor },
                                             }}
                                             onClick={handleClick}
                                          />
                                          <Typography>{primaryColor}</Typography>
                                       </Box>
                                    </Tooltip>

                                    <Tooltip title={"Color secundario"} placement="right-end">
                                       <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                          <Button
                                             id="color-picker-2"
                                             aria-describedby={popoverId}
                                             sx={{
                                                borderRadius: 1,
                                                maxWidth: 35,
                                                height: 20,
                                                bgcolor: secondaryColor,
                                                ":hover": { bgcolor: secondaryColor },
                                             }}
                                             onClick={handleClick}
                                          />
                                          <Typography>{secondaryColor}</Typography>
                                       </Box>
                                    </Tooltip>
                                 </Stack>
                              </Stack>
                           </Box>
                           <Popover
                              id={popoverId}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                 vertical: "bottom",
                                 horizontal: "left",
                              }}
                           >
                              <ChromePicker
                                 color={selectedColorPicker === "color-picker-1" ? primaryColor : secondaryColor}
                                 onChangeComplete={handleChangeComplete}
                                 disableAlpha={true}
                              />
                           </Popover>
                        </Box>
                     </Box>
                  </Box>
                  <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                     <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                        Cancelar
                     </Button>
                     <LoadingButton
                        label={group ? "Guardar cambios" : "Continuar"}
                        isLoading={isLoading || isLoadingFiles || isLoadingUpload}
                     />
                  </Box>
               </Form>
            </Formik>
         ) : (
            <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
               <CircularProgress size={24} />
            </Box>
         )}
      </Box>
   );
};
